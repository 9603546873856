body {
    font-family: "SimHei","Heiti SC","黑体-简","Microsoft YaHei",;
}
.App {
    background-color: #3875C5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
}

a:link {color:#89B0E2;}      /* 未访问链接*/
a:visited {color:#89B0E2;}  /* 已访问链接 */
a:hover {color:#89B0E2;}  /* 鼠标移动到链接上 */
a:active {color:#89B0E2;}  /* 鼠标点击时 */
.part-title {
    height: 10em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
} 

.part-form {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* background-image: url(./sagume.png);
    background-position: -100px -50px;
    background-repeat: no-repeat; */
}

.title{
    width: 410px;
}

.max-width-in-pc {
    width: 100%;
    max-width: 480px;
}
.form {
    padding: 10%;
}

h1 {
    margin: 0;
    font-size: 280%;
}

h2 {
    margin: 0;
    font-size: 170%;
}

.form-input {
    background-color: white;
    margin-top: 3%;
    height: 2.5rem;
    border: 2px solid #124280;
    box-sizing: border-box;
    /* border-radius: 50px; */
    color: #AAC2E0;
}

.query-button {
    height: 3rem;
    margin-top: 5rem;
    width: 100%;
    background: #124280;
    /* border-radius: 50px; */
}

.align-right {
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
}

.font-color-gray {
    /* color: #89B0E2; */
    color: #89B0E2;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.dialog-title{
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
}
.dialog-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 2rem;
}
.dialog-p{
    padding: 0;
    margin: 0;
}
.dialog-info{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 5%;
}
.dialog-info-left{
    vertical-align:text-bottom;
    font-size: smaller;
    color: #AAA;
}
.mobile-logo{
    display: none;
}
.footer{
    height: auto;
    padding-top: auto;
    padding-bottom: auto;
    text-align: center;
}

.msg-toast{
  background-color: #c74040;
  border: none;
  color: white;
}

@media screen and (max-width: 800px) {
    .part-form{
        background-size: cover;
        background-position: bottom;
    }
    .part-title {
        height: 8em;
    }
    .title{
        width: 100%;
        padding: 2%;
    }
    h1 {
        margin: 0;
        font-size: 2.2rem;
    }
    
    h2 {
        margin: 0;
        font-size: 1.5rem;
    }
    .form-input {
        background-color: white;
        margin-top: 3%;
        height: 2.5rem;
    }
    .query-button {
        background-color: #124280;
        width: 100%;
    }

    .form {
        padding: 3%;
        padding-top: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mobile-logo{
        display: none;
    }

    .part-r-buttom {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .footer{
        padding-top: auto;
        padding-bottom: auto;
        text-align: center;
    }

    .max-width-in-pc {
        width: 100%;
        max-width:100%;
    }

    

}

@media screen and (max-height: 530px) {
    .part-title {
        height: 5em;
    }
    .footer{
        padding-top: 1rem;
        text-align: center;
    }
    .query-button{
        margin-top: 3rem;
    }
}